import './Dashboard.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
const Dashboard = () => {


  const colors = ['#95A4FC', '#BAEDBD', '#1C1C1C', '#B1E3FF', '#A8C5DA', '#A1E3CB'];

  const data = [
    {
      name: 'Linux',
      uv: 1800,
      
    },
    {
      name: 'Mac',
      uv: 3000,
      
    },
    {
      name: 'iOS',
      uv: 2000,
    
    },
    {
      name: 'Android',
      uv: 2780,
     
    },
    {
      name: 'Android',
      uv: 900,
  
    },
    {
      name: 'Other',
      uv: 2000,
   
    },
  ];
  
  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
${x + width / 2},${y}
C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width},${y + height}
Z`;

  };
  
  
  return (
    <div>
      {/* Heading */}
      <h1 className="textAwareness">Awareness Manager</h1>

      {/* Dropdown Section */}
      <div className="color">
        <div className="inline">
          {/* First Dropdown */}
          <div className="dropdown-item">
            <h1 className="selectdepartment">Select Department</h1>
            <select className="select " aria-label="Select Department">
              <option defaultValue>Select Department</option>
              <option value="Computer Department">Computer Department</option>
              <option value="Bio Department">Bio Department</option>
              <option value="Account Department">Account Department</option>
            </select>
          </div>

          {/* Second Dropdown */}
          <div className="dropdown-item">
            <h1 className="selectdepartment">Select Campaign</h1>
            <select className="select " aria-label="Select Campaign">
              <option defaultValue>Select Campaign</option>
              <option value="Computer Department">Computer Department</option>
              <option value="Bio Department">Bio Department</option>
              <option value="Account Department">Account Department</option>
            </select>
          </div>

          {/* Export Analytics Button */}
          <div className="select1">
          <i className="bi bi-upload"></i>
          <p className="export">Export Analytics</p>
        </div>

        </div>

        {/* Advanced Search Filters */}
        <div className="inline-container">
          <i className="icon bi bi-gear"></i>
          <p className="lasttext">Advanced Search Filters</p>
        </div>
      </div>

      {/* Training Campaigns Section */}
      <div className="color" style={{ marginTop: '25px' ,    background: '#EFF4F9'}}>
        <h1 className="Training Campaign">Training Campaign</h1>
        <div className='train'>
        <div style={{display:'flex'}}>
        <div >
        <p class="button">5</p></div>
      <div>
        <div>
        <p class="textComplete" style={{marginLeft:'15px'}}>Completed Training Campaigns</p>
        <p className='paragraph'>Training campaigns that users have been completed</p></div>
      </div>
      <div>
        <p class="button">4</p></div>
      <div>
        <div>
        <p class="textComplete" style={{marginLeft:'15px'}}>Active Training Campaigns</p>
        <p className='paragraph'>Active training campaigns that are already running</p></div>
      </div>
      <div>
        <p class="button">12</p></div>
      <div>
        <div>
        <p class="textComplete" style={{marginLeft:'15px'}}>Completed Training Campaigns</p>
        <p className='paragraph'>Planned Campaigns which will be starting</p></div>
      </div>
</div>
      </div>

</div>


      {/* Phishing Campaigns Section */}
      <div className="color" style={{ marginTop: '25px',      background: '#EFF4F9'}}>
        <h1 className="Training Campaign">Phishing Campaigns</h1>
        <div style={{ display: 'flex', gap: '90px', alignItems: 'flex-start', marginTop:'50px' }}>
    <div >
      <p className="textComplete" >Completed Phishing Campaigns</p>
      <p className="text1">Phishing campaigns that are completed</p>
    </div>
    <div >
      <p className="textComplete">Active Phishing Campaigns</p>
      <p className="text1">Active Phishing campaigns that are already running</p>
    </div>
    <div>
      <p className="textComplete">Planned Phishing Campaigns</p>
      <p className="text1">Planned Phishing campaigns which will start soon</p>
    </div>
    </div>
      </div>

      {/* Campaign Engagement Section */}
      <div className="color" style={{ marginTop: '25px',    background: '#EFF4F9' }}>
        <h1 className="Campaign Engagement">Campaign Engagement</h1>
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}>
 
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="uv" fill="#8884d8" radius={9.932}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
          <ul style={{  margin: 0, padding: 0, marginTop:'120px'}}>
            <li>Community Management 15%</li>
            <li>Construction Delivery 41%</li>
            <li>Safety Security 11%</li>
            <li>Other</li>
          </ul>
        </div>
          </div>
          <div>
          </div>

    </div>
  );
};


export default Dashboard;