import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditUser.css';

const EditUser = ({id}) => {

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [userId] = useState(id);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        username: "",
        role_id: "",
        status_id: "",
        source_id: "2"
    });

    const fetchUserData = async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(api_url + "/admin/main/users/" + userId, {
				mode: 'cors',
				method: "GET",
			  	headers: {
					"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
			  	}
			});
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching user details.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["firstname"]: data.firstname,
                ["lastname"]: data.lastname,
                ["email"]: data.email,
                ["phone_number"]: data.phone_number,
                ["username"]: data.username,
                ["role_id"]: data.role_id,
                ["status_id"]: data.status_id,
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleEditUser = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/users/update/" + userId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("User updated successfully!");
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update user. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" /> Edit
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">Edit User {id}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">First Name</label>
                                <input type="text" name="firstname" className="form-control" onChange={handleChange} value={formData.firstname} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Last Name</label>
                                <input type="text" name="lastname" className="form-control" onChange={handleChange} value={formData.lastname} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" name="email" className="form-control" onChange={handleChange} value={formData.email} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Phone Number</label>
                                <input type="text" name="phone_number" className="form-control" onChange={handleChange} value={formData.phone_number} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Username</label>
                                <input type="text" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={handleEditUser}>
                        Update User
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditUser;