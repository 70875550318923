import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddDepartment from '../../Components/AddDepartmentModal/AddDepartment';
import './Department.css'; // Ensure this path is accurate

const Department = () => {
  const [data, setDepartment] = useState([]);
  const [links, setLinks] = useState([]);

  const fetchDepartment = async (url) => {
    console.log(url);
    let page = 1;
    if (url !== '') {
      const temp = url.split('?page=');
      page = temp[1];
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${api_url}/admin/main/departments?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setDepartment(result.data);
      setLinks(result.links);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Uncomment these lines if you want to handle token issues
      // localStorage.removeItem("userToken");
      // localStorage.removeItem("userData");
      // window.location = "/login";
    }
  };

  useEffect(() => {
    fetchDepartment('');
  }, []);

  // Define table columns
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/images/fi_link.png" alt="Department Name" /> Department Name
          </div>
        ),
      },
      {
        accessorKey: 'created_at',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/images/fi_clock.png" alt="Date of Creation" /> Date of Creation
          </div>
        ),
      },
      
       
      {
        accessorKey: 'id',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/images/fi_alert-octagon.png" alt="Action" /> Action
          </div>
        ),
        cell: ({ getValue }) => {
          const id = getValue();
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px'  , width:'70%'}}>
              <div className="iconEdit">
                <NavLink to={`/user/edit/${id}`} className="">
                  <img src="/images/edit.png" alt="Edit" /> Edit
                </NavLink>
              </div>
              <div className="iconDel">
                <NavLink to={`/user/delete/${id}`}>
                  <img src="/images/fi_trash-2.png" alt="Delete" /> Delete
                </NavLink>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  // Create a table instance
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="p-4">
      <div className="pageTitle mb-4">Departments</div>
      
      { localStorage.getItem('userToken') }
      <div className="primaryBlueBox p-5 mb-4">
        <div className="row">
          <div className="col-md-4">
            <label className="form-label">Department By Name</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="department by name" aria-label="department by name " />
              <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-1">
            <div className="vrLine"></div>
          </div>
          <div className="col-md-7">
            <AddDepartment />
          </div>
        </div>
      </div>

      <div className="bg-light px-3 py-3 userTableWrapper" >
        <table className="table table-border">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="navigationWrapper">
        <ul>
          {links.map((link, index) => (
            <li
              key={index}
              className={`${link.active ? 'active' : ''} ${link.url === null ? 'disabled' : ''}`}
              onClick={link.url !== null ? () => fetchDepartment(link.url) : undefined}
            >
              <div dangerouslySetInnerHTML={{ __html: link.label }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Department;
