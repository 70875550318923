import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddUser from '../../Components/AddUserModal/AddUser';
import EditUser from '../../Components/EditUserModal/EditUser';
import './Users.css';

const Users = () => {

    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');

    const handleDelete = async(id) => {
        
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/users/destory/" + id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                }
            });

            if (response.ok) 
            {
                setDeleted("User deleted successfully!");
            } 
            else 
            {
                setDeleted("Error!! Failed to delete user. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
        }

    }

    
    const fetchUsers = async (url) => {
        console.log(url);
        var page = 1;
        if (url !== '')
        {
            const temp = url.split('?page=');
            var page = temp[1];
        }
        

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/admin/main/users?page=' + page, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`, 
                },
              });
          
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }

            const result = await response.json();
            setUsers(result.data);
            setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            //localStorage.removeItem("userToken");
            //localStorage.removeItem("userData");
            //window.location = "/login";
        }
    };

    useEffect(() => {
        fetchUsers('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
        {
            accessorKey: 'firstname',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_user.svg" alt="First Name" /> First Name
                </div>
            ),
        },
        {
            accessorKey: 'lastname',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_user.svg" alt="Last Name" /> Last Name
                </div>
            ),
        },
        {
            accessorKey: 'source_id',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_align-left.svg" alt="Source" /> Source
                </div>
            ),
        },
        {
            accessorKey: 'status_id',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_loader.svg" alt="Source" /> Status
                </div>
            ),
            cell: ({ getValue }) => {
                const status = getValue();
                return (
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {status === 1 ? (
                        <div className="statusActive badgeActive">
                            Active
                        </div>
                    ) : (
                        <div className="statusInactive badgeInactive">
                            Inactive
                        </div>
                    )}
                  </div>
                );
            },
        },
        {
            accessorKey: 'role_id',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_tool.svg" alt="Source" /> Role
                </div>
            ),
        },
        {
            accessorKey: 'id',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_alert-octagon.png" alt="Source" /> Action
                </div>
            ),
            cell: ({ getValue }) => {
                const id = getValue();
                return (
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <EditUser id={id} />
                    <div className="iconDel" onClick={() => handleDelete(id)}>
                        <img src="/images/fi_trash-2.png" alt="Delete" /> Delete
                    </div>
                  </div>
                );
            },
        },
        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">

            <div className="pageTitle mb-4">All Users</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">Search User</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <AddUser />
                    </div>
                </div>
            </div>

            { deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}

            <div className="bg-light px-3 py-3 userTableWrapper">
            <table className="table table-border">

                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                    </tr>
                    ))}
                </thead>

                <tbody>
                    {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>

            <div className="navigationWrapper">
                <ul>
                {links.map((link, index) => (
                    
                    <li key={index} 
                        className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                        onClick={link.url !== null ? () => fetchUsers(link.url) : undefined}
                        >
                        <div dangerouslySetInnerHTML={{ __html: link.label }} />
                    </li>
                ))}
                </ul>
            </div>

        </div>
    );
}

export default Users;