import { useLocation, NavLink } from 'react-router-dom';
import './LeftNav.css';

function LeftNav() {
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <div className="col-md-3 pt-4 pb-4 siteLeft">
            <div className="logo ps-3">
                <img src="/images/ByeWind.png" alt="Cyber Sense" />
            </div>

            <div className="sidebarNavWrapper">
                <div className="pageName ps-3 pt-5">
                    Dashboard
                </div>
                <nav className="vh-100 p-3">
                    <ul className="list-unstyled">
                        <li className="first-item py-2">
                            <NavLink
                                to="/dashboard"
                                className={`d-block py-2 px-2 ${isActive("/dashboard") ? "active" : ""}`}
                                >
                                <i className="bi bi-pie-chart me-2 text-bluelightsense"></i> Analytics
                            </NavLink>
                        </li>
                        <li className="first-item py-2">
                            <a
                            className="d-flex align-items-center  py-2 px-2"
                            data-bs-toggle="collapse"
                            href="#dashboardcon"
                            role="button"
                            aria-expanded="false"
                            aria-controls="dashboardcon"
                            >
                                <i className="bi bi-grid me-2 text-bluelightsense"></i> Dashboard Configuration <i className="ms-auto bi bi-chevron-down"></i>
                            </a>
                            <div className="collapse" id="dashboardcon">
                                <ul className="list-unstyled ps-4">
                                    <li>
                                        <a href="#sub1-1" className="d-block  py-1 px-2">
                                            Phishing Simulator
                                        </a>
                                    </li>
                                    <li>
                                        
                                        <a
                                            className="d-flex align-items-center  py-2 px-2"
                                            data-bs-toggle="collapse"
                                            href="#usermanagement"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="usermanagement"
                                        >
                                            User Management <i className="ms-auto bi bi-chevron-down"></i>
                                        </a>
                                        <div className="collapse" id="usermanagement">
                                            <ul className="list-unstyled ps-4">
                                                <li>
                                                    <NavLink
                                                        to="/users"
                                                        className={`d-block py-2 px-2 ${isActive("/users") ? "active" : ""}`}
                                                        >
                                                        All Users
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <a href="#sub1-1" className="d-block  py-1 px-2">
                                                        Compliance Manager
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>    
                                    </li>
                                    <li>
                                        <a href="#sub1-2" className="d-block  py-1 px-2">
                                            Awareness Manager
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#sub1-2" className="d-block  py-1 px-2">
                                            Compliance Manager
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#sub1-2" className="d-block  py-1 px-2">
                                            PhishScout
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#sub1-2" className="d-block  py-1 px-2">
                                            Survey Manager
                                        </a>
                                    </li>
                                    <li>
                                    <NavLink
                                            to="/department"
                                            className={`d-block py-2 px-2 ${isActive("/department") ? "active" : ""}`}
                                              >
                                            Department
                                            </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        
                        <li className="first-item py-2">
                            <a href="#home" className="d-block  py-2 px-2">
                                <i className="bi bi-exclamation-triangle me-2 text-bluelightsense"></i> Phishing Management
                            </a>
                        </li>

                        <li className="first-item py-2">
                            <a href="#home" className="d-block  py-2 px-2">
                                <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> Awareness Management
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default LeftNav;
