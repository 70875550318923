import React, { useState } from 'react';
import { api_url } from '../../constant/constant';
import './Login.css';
import { Toast } from 'react-bootstrap';

function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showError, setShowError] = useState(false);

	const handleSubmit = async(e) => {

    	e.preventDefault();
		setShowError(false);

		try 
		{
			const response = await fetch(api_url + "/login", {
				mode: 'cors',
				method: "POST",
			  	headers: {
					"Content-Type": "application/json",
			  	},
			  	body: JSON.stringify({ login: email, password: password }),
			});
	  
			if (!response.ok) 
			{
				setShowError(true);
			  	throw new Error("Login failed. Check your credentials.");
			}
	  
			const data = await response.json();
			setShowError(false);
			localStorage.setItem("userToken", data.token);
			localStorage.setItem("userData", JSON.stringify(data));
			window.location = "/dashboard";
		} 
		catch (err) 
		{
			console.log(err.message);
			setShowError(true);
		}

  	};

  return (
	<div className="container">
		<div align="right" className="m-3">
          <Toast show={showError}>
            <Toast.Header closeButton={false} className="bg-danger text-white">
              <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body className="text-danger txtErrorLogin">Invalid Login details.</Toast.Body>
          </Toast>
		</div>
		
		<div className="row justify-content-center">
        	<div className="col-md-5">
				<div className="loginLogo mt-5" align="center">
					<img src="/images/logo.png" alt="Cyber Sense" />
				</div>
          		<div className="card mt-5 pb-5" style={{ borderRadius: '30px' }}>
            		<div className="card-body">

						<h2 className="m-5 txtLoginAccount" align="center">Login account</h2>

						<div className="ps-5 pe-5">

							<div className="ps-5 pe-5">

								<form onSubmit={handleSubmit} className="logoForm" >
									<div className="form-group mb-3">
									<label htmlFor="email">Email</label>
									<input
										type="email"
										className="form-control"
										id="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required
									/>
									</div>
									<div className="form-group mb-3">
									<label htmlFor="password">Password</label>
									<input
										type="password"
										className="form-control"
										id="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
									</div>
									<button type="submit" className="btn btn-dark w-100 p-2">
									Login Account
									</button>
								</form> 

								<div className="mt-3 mb-3 text-aha text-center">
									Don't have account? <a href="#register">Sign up</a>
								</div>

								<div className="line-text">
									<div className="line"></div>
									<span className="text">OR</span>
									<div className="line"></div>
								</div>

								<div className="loginGoogle mt-3" align="center">
									<img src="/images/signupgoogle.png" alt="Signup with Google" />
								</div>

								<div className="loginMicrosoft mt-2" align="center">
									<img src="/images/signupmicrosoft.png" alt="Signup with Microsoft 365" />
								</div>



							</div>

						</div>

            		</div>
          		</div>

				  <div className="mt-3 mb-3 text-aha text-center">
					&copy; 2023 All rights reserved.
				</div>
        	</div>
      	</div>
    </div>
  );
}

export default Login;
