import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddDepartment.css';
import Department from "../../Pages/Department/Department";

function AddDepartment() {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        DepartmentName: "",
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddDepartment = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/departments/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage("Department added successfully!");
                setFormData({
                    DepartmentName: "",
                });
            } else {
                setSuccessMessage("");
                setErrorMessage("Failed to add department. Please try again.");
            }
        } catch (error) {
            setSuccessMessage("");
            setErrorMessage(error.message);
        }
    };

    return (
        <div>
            <label>Add New Department</label>
            <br />
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="AddDepartment" /> Add
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">Add New Department</Modal.Title>
                </Modal.Header>

                <Modal.Body className="adddepatmentWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Department Name</label>
                                <input
                                    type="text"
                                    name="DepartmentName"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={formData.DepartmentName}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={handleAddDepartment}>
                        Add Department
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddDepartment;
