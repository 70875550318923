import './App.css';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Users from './Pages/Users/Users';
import AwarenessManager from './Pages/AwarenessManager/AwarenessManager';
import NotFound from './Pages/NotFound/NotFound';
import Root from './Pages/Root/Root';
import Department from './Pages/Department/Department';
import { useEffect, useState } from 'react';


// Loader function to protect routes
const checkAuth = () => {
	const isAuthenticated = localStorage.getItem('userToken');
	if (!isAuthenticated) 
	{
		return redirect('/login');
	}
	return null;
};

// Define Routes
const router = createBrowserRouter([
	{
		path:'/',
		element: <Root />,
		errorElement: <NotFound />,
		children: [
			{ index: true, element: <Dashboard />, loader: checkAuth}, 
	  		{ path: '/login', element: <Login />}, 
	  		{ path:'/dashboard', element: <Dashboard />, loader: checkAuth},
			{ path:'/users', element: <Users />, loader: checkAuth},
			{ path:'/awareness-manager', element: <AwarenessManager />, loader: checkAuth},
			{ path: '/department', element: <Department /> } 

  		],
   },  
]);

function App() {

	const [userLoggeIn, setUserLoggedIn] = useState(false);

	useEffect(() => {
		console.log( localStorage.getItem("userToken") );
		if (localStorage.getItem("userToken") !== null)
		{
			setUserLoggedIn(true);
		}
	}, []);

	return (
		<div className={`app ${userLoggeIn ? "loggedin" : ""}`}>
			<RouterProvider router={router} />
		</div>
	);

	/*
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/dashboard" element={<Dashboard />} />
			</Routes>
		</div>
	  ); */
}

export default App;
