import { useEffect, useState } from 'react';
import './Head.css';

const Head = () => {

    const [firstName, setFirstName] = useState('Sibtain');

    useEffect(()=>{
        var jsonData = localStorage.getItem("userData");
        var userData = JSON.parse(jsonData);
        setFirstName( userData.user.firstname );
    }, []);

    const handleLogout = () => {
        
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        window.location = "/login";
    }

    return (
        <div className="p-4 siteHeadWrapper">

            <div className="rightHeadWrapper">
                
            </div>

            <div className="leftHeadWrapper">
                <div className="helpWrapper">
                    <i className="bi bi-question-circle"></i> Help
                </div>
                
                <div className="profileWrapper d-flex align-items-center">
                    <span className="profileLogo">
                        <img src="/images/profile.png" width="32" alt="{firstName}" />
                    </span>

                    <span className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#profile" id="submenu" role="button">
                            {firstName}
                        </a>
                        <ul className="dropdown-menu submenu" aria-labelledby="submenu">
                            <li><a className="dropdown-item submenu-item" href="#profile">Profile</a></li>
                            <li><a className="dropdown-item submenu-item" href="#settings">Settings</a></li>
                            <li>
                                <button 
                                    className="dropdown-item submenu-item" 
                                    onClick={handleLogout}
                                    >Logout</button>
                            </li>
                        </ul>
                    </span> 
                </div>
            </div>
            
        </div>
    );
}

export default Head;